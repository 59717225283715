<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        跨界揪健康
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="企業員工線上運動 開發AI健促服務">
        <p class="image_desc">企業員工線上運動 開發AI健促服務</p>
        <p class="image_desc">圖片為活動主視覺圖片</p>
        <p class="image_desc">(圖片提供：虹映科技)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          根據美國運動醫學會(ACSM)於2023年1月的全球健身趨勢調查結果顯示，「穿戴式科技」在全球健身趨勢中位居第一，透過AI人工智能技術輔助並以數據反饋運動成效。
        </div>
        <div class="content-desc">
          JoiiSports app串接多種穿戴裝置，經過AI演算回饋會員運動和生理數據的建議與分析，進一步媒合JoiiESG生態圈服務提供個人化的健康促進的OMO服務、企業方案。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          虹映科技協手昊霖健康一同辦理「跨界揪健康」。
        </div>
        <div class="content-desc">
          於112年8月21日至11月30日止，共完成累計上傳3.3萬筆，其中含平均心率、消耗卡路里、達成次數等運動生理數據，完整數據留存原始資料於JoiiSports app中，並已與運動數據公益平台串接。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          企業健促運動服務業者與健康服務業者，發展企業員工健康促進商模設計，提供員工客製化收費服務。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          應用於JoiiESG生態服務圈，回饋會員即時運動健促資訊、AI風險分析與改善建議，衍生個人化健促服務進而帶動更大銷量，達到共創營收利潤的目的。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11204',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-04.jpg')
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
